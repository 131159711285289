// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles.
import './Graphic.scss';

const Graphic = ({ src, top, bottom, left, right, middle, className }) => {
    const classes = classnames({
        'Graphic': true,
        'top': top,
        'bottom': bottom,
        'left': left,
        'right': right,
        'middle': middle,
        [className]: className,
    });

    return (
        <img className={ classes } src={ src } alt="" />
    );
};

Graphic.propTypes = {
    src: PropTypes.string.isRequired,
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    middle: PropTypes.bool,
    className: PropTypes.string,
};

Graphic.defaultProps = {
    top: false,
    bottom: false,
    left: false,
    right: false,
    middle: false,
    className: null,
};

export default Graphic;
