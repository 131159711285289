// Modules.
import React from 'react';
import classnames from 'classnames';

// Components.
import Graphic from 'components/Graphic';

// Styles.
import './BGLines.scss';

const BGLines = ({ top, bottom }) => {
    const classes = classnames({
        'BGLines': true,
    });

    return (
        <div className={ classes }>
            { top && <Graphic src="/images/graphic-lines-left.png" className="bg-lines-top" top left /> }
            { top && <Graphic src="/images/graphic-lines-right.png" className="bg-lines-top" top right /> }
            { bottom && <Graphic src="/images/graphic-lines-left.png" className="bg-lines-bottom" bottom left /> }
            { bottom && <Graphic src="/images/graphic-lines-right.png" className="bg-lines-bottom" bottom right /> }
        </div>
    );
};

export default BGLines;
