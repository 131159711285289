// Modules.
import React from 'react';
import { Link } from 'react-router-dom';

// Utils.
import { localize, getLocalization } from 'utils/localization';

// Styles.
import './Header.scss';

const Header = () => {
    return (
        <header className="Header">
            <Link to="/">{ localize('LOGO') }</Link>
            <div className="language-toggle">
                { !getLocalization() && <a className="button" href="https://www.surveymonkey.com/r/CYRFeedback" target="_blank" rel="noopener noreferrer">Feedback</a> }
                { getLocalization() ? <a href="https://checkyourreflex.ca">{ localize('ENGLISH') }</a> : <a href="https://testezvosreflexes.ca">{ localize('FRENCH') }</a> }
            </div>
        </header>
    );
};

export default Header;
