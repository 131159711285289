// Modules.
import React from 'react';
import classnames from 'classnames';

// Components.
import Graphic from 'components/Graphic';

// Styles.
import './BGEmojis.scss';

// Images.
import HAPPY from './img/happy.png';
import SAD from './img/sad.png';
import SURPRISED from './img/surprise.png';

const BGEmojis = () => {
    const classes = classnames({
        'BGEmojis': true,
    });

    return (
        <div className={ classes }>
            <Graphic src={ HAPPY } className="bg-emoji-happy" top left />
            <Graphic src={ SAD } className="bg-emoji-sad" middle right />
            <Graphic src={ SURPRISED } className="bg-emoji-surprised" bottom left />
        </div>
    );
};

export default BGEmojis;
