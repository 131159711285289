// Modules.
import React from 'react';

// Utils.
import { localize } from 'utils/localization';

// Styles.
import './Contact.scss';

const Contact = () => {
    return (
        <section className="Contact">
            { localize('CONTACT_QUOTE') && <blockquote>{ localize('CONTACT_QUOTE') }</blockquote> }
            { localize('CONTACT_CONTENT') }
        </section>
    );
};

export default Contact;
