// Modules.
import React, { useEffect } from 'react';

// Components.
import BGLines from 'components/BGLines';
import BGEmojis from 'components/BGEmojis';

// Sections.
import PlayCheckYourReflex from './elements/PlayCheckYourReflex';
import LetsTalk from './elements/LetsTalk';
import Suggestions from './elements/Suggestions';
import Contact from './elements/Contact';

// Styles.
import './HomeSection.scss';

const HomeSection = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="HomeSection">
            <BGLines top bottom />
            <BGEmojis />

            <main className="container">
                <PlayCheckYourReflex />
                <LetsTalk />
                <Suggestions />
                <Contact />
            </main>

        </div>
    );
};

export default HomeSection;
