// Modules.
import React from 'react';

// Utils.
import { localize } from 'utils/localization';

// Styles.
import './LetsTalk.scss';

const LetsTalk = () => {
    return (
        <section className="LetsTalk">
            <h2>{ localize('LETS_TALK_HEADER') }</h2>
            <h3>{ localize('LETS_TALK_DESCRIPTION') }</h3>
            <ul className="letstalk-facts">
                {
                    localize('LETS_TALK_FACTS') && localize('LETS_TALK_FACTS').map((fact, key) => (
                        <li key={ key }>{ fact }</li>
                    ))
                }
            </ul>
            <h3>{ localize('LETS_TALK_PRE_EFFECTS') }</h3>
            <ul className="letstalk-effects">
                {
                    localize('LETS_TALK_EFFECTS') && localize('LETS_TALK_EFFECTS').map((fact, key) => (
                        <li key={ key }>{ fact }</li>
                    ))
                }
            </ul>
        </section>
    );
};

export default LetsTalk;
