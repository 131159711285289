// Modules.
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

// Sections.
import Header from 'sections/Header';
import Footer from 'sections/Footer';

import HomeSection from 'sections/HomeSection';
// import ContestSection from 'sections/ContestSection';
import PrivacyPolicySection from 'sections/PrivacyPolicySection';
// import RulesSection from 'sections/RulesSection';

// Utils.
import { setLocalization } from 'utils/localization';

// Styles.
import './App.scss';

const App = () => {
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const lang = params.get('lang');

        if (lang) setLocalization(lang);
        setLoaded(true);

        if (window.location.hostname.indexOf('testezvosreflexes') > -1) {
            setLocalization('fr');
        }

    }, []);

    if (!loaded) return null;

    return (
        <div className="App">
            <BrowserRouter>
                <Header />
                    <Route path='/' component={ HomeSection } exact />
                    {/* <Route path='/contest' component={ ContestSection } exact />
                    <Route path='/contest-rules' component={ RulesSection } exact /> */}
                    <Route path='/privacy-policy' component={ PrivacyPolicySection } exact />
                <Footer />
            </BrowserRouter>
        </div>
    );
};

export default App;
