// Modules.
import React from 'react';

// Utils.
import { localize } from 'utils/localization';

// Styles.
import './PlayCheckYourReflex.scss';

const PlayCheckYourReflex = () => {
    return (
        <section className="PlayCheckYourReflex">
            <h1>{ localize('PLAY_CHECK_YOUR_REFLEX') }</h1>
            <a className="button" href="/game/" target="_blank"  rel="noopener noreferrer">{ localize('PLAY_CHECK_YOUR_REFLEX_CTA') }</a>
            { localize('PLAY_CHECK_YOUR_REFLEX_DESCRIPTION') }
        </section>
    );
};

export default PlayCheckYourReflex;
