// Modules.
import React from 'react';
import { Link } from 'react-router-dom';

// Utils.
import { localize, getLocalization } from 'utils/localization';

// Styles.
import './Footer.scss';

// Images.
import logo from './img/RGClogo.png';
import logoFR from './img/RGClogoFR.png';

const Footer = () => {
    const hasBothLinks = localize('CONTEST_RULES') && localize('PRIVACY');

    return (
        <footer className="Footer">
            <div className="container">
                <div className="links">
                     <Link to="/contest-rules">{ localize('CONTEST_RULES') }</Link>{ hasBothLinks && ' | ' }<a href={ localize('PRIVACY_URL') }>{ localize('PRIVACY') }</a>
                </div>
                <p>{ localize('FOOTER_PRE_LOGO') }</p>
                <a href={ !getLocalization() ? "https://www.responsiblegambling.org/" : "https://www.responsiblegambling.org/fr/" } target="_blank" rel="noopener noreferrer">
                    <img src={ !getLocalization() ? logo : logoFR } alt={ !getLocalization() ? "RGC" : "CJR" } />
                </a>
                <p>{ localize('FOOTER_POST_LOGO') }</p>
            </div>
        </footer>
    );
};

export default Footer;
