// Modules.
import React, { useEffect } from 'react';

// Components.
import BGLines from 'components/BGLines';

// Utils.
import { localize } from 'utils/localization';

// Styles.
import './PrivacyPolicySection.scss';

const PrivacyPolicySection = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="PrivacyPolicySection">
            <BGLines top />
            <main className="container">
                { localize('PRIVACY_POLICIES') }
            </main>
        </div>
    );
};

export default PrivacyPolicySection;
