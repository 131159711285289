// Modules.
import React from 'react';

// Utils.
import { localize } from 'utils/localization';

// Styles.
import './Suggestions.scss';

const Suggestions = () => {
    return (
        <section className="Suggestions">
            { localize('SUGGESTIONS_OPENING') }
            {
                localize('SUGGESTIONS_POINTS') &&
                <ul>
                    {
                        localize('SUGGESTIONS_POINTS').map((point, key) => (
                            <li key={ key }>{ point }</li>
                        ))
                    }
                </ul>
            }
        </section>
    );
};

export default Suggestions;
