import en from 'localization/en.localization';
import fr from 'localization/fr.localization';

let current = null;

export const Languages = {
    ENGLISH: 'en',
    FRENCH: 'fr',
};

export const getLocalization = () => {
    return current;
};

export const setLocalization = locale => {
    if (locale) current = locale.toLowerCase();
};

export const localize = key => {
    switch (current) {
        case Languages.FRENCH: {
            if (!fr[key]) {
                console.error(`[localize] Missing French String: ${ key }`);
                return;
            }
            return fr[key];
        }

        default: {
            if (!en[key]) {
                console.error(`[localize] Missing English String: ${ key }`);
                return;
            }

            return en[key];
        }
    }
};
